
.notification-widget-dropdown {
  border: 1px solid var(--c-gray);
  background: var(--c-bg);
  overflow: hidden;

  &__header {
    position: relative;
    border-bottom: 1px solid var(--c-gray);
    box-shadow: var(--box-shadow-1);
    padding-inline: var(--margin-m);
    padding-block: var(--margin-m);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--margin-xl);
    min-width: 300px;
    text-align: center;
  }

  &__close-icon {
    position: absolute;
    z-index: var(--z-level-2);
    inset-inline-start: var(--margin-m);
    top: 50%;
    cursor: pointer;
    border-radius: 50%;
    transform: translateY(-50%);
    transition: transform 250ms ease;

    &:hover {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__content {
    max-height: 300px;
    overflow-y: auto;
  }

  &__footer {
    border-top: 1px solid var(--c-gray);
    box-shadow: var(--box-shadow-1);
    padding-inline: var(--margin-m);
    padding-block: var(--margin-m);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--margin-xl);
    min-width: 300px;
    text-align: center;
  }
}
